<template>

    <header class='sign-up-header'>
            <img src='../../public/Invrs-Logo-Green-Blue.png' class="nav-image" style="cursor: pointer;" @click="goHome()">
            <div class="nav-items">
                <a class="login-link" @click="goToLoginPage">Login</a>
                <button class="signup-cta" @click="goToSignUpPage">Sign Up</button>
            </div>
    </header>
    <div class="shared-post-div" v-if="activity">
        
        <div class='activity-title' v-if="activity.title">
               
            <template v-if="activity.verb.startsWith('question')">
                <span class="material-symbols-outlined question-post poll" v-if="activity.poll?.pollTypeId == MULTIPLE_CHOICE_POLL.pollTypeId || activity.poll?.pollTypeId == MULTIPLE_SELECT_POLL.pollTypeId">
                list_alt
                </span>
                <span class="material-symbols-outlined question-post poll" v-else-if="activity.poll?.pollTypeId == YESNO_POLL.pollTypeId">
                ballot
                </span>
                <span class="material-symbols-outlined question-post">
                emoji_people
                </span>
            </template>
            <span class="material-symbols-outlined question-post" v-else-if="activity.verb.startsWith('motion')">
                campaign
            </span>
            
            <h1 class="shared-page-title"> {{ activity.title }}</h1>
        </div>
        <div class='status-post-owner-info' >
            <UserAvatar :imageSrc='activity.actor.data.avatar' customStyle='width: 36px; height: 36px;' @click="openAccountNeededModal" />
            <div class="names-container">
                <span class='full-name' @click="openAccountNeededModal">{{stringUtils.fullName(activity.actor.data)}}</span>
                <span class='username' @click="openAccountNeededModal"><span>@{{activity.actor.data.name}}</span>
                    | {{timeUtils.getTimeFromNow(activity.time)}}{{ (activity.revision_date ? ' | Edited '+timeUtils.getTimeFromNow(activity.revision_date) : '')}}</span>
            </div>
            
        </div>
          <template v-if="activity.verb.startsWith('motion')">
            <div class="trade-details-section">
                <div class="trade-details-header">Trade Details</div>
                <div class="trade-details-content">
                    Place <b v-if="mainTradeOrderObject.tradeOrderType === 'Market'">{{ mainTradeOrderObject.tradeType === 'Buy' ? 'Buy' : 'Sell'}}</b>
                        <b v-else>{{mainTradeOrderObject.tradeType}}</b> order for <b>{{ mainTradeOrderObject.quantity }}</b> units of <b>{{ mainTradeOrderObject.symbol }}</b> <span v-html="mainTradeOrderText"></span>
                        <p>
                        
                        <b>Portfolio: {{activity.motion.portfolioName}}</b><br>
                        <b>Account: {{activity.motion.accountName}}</b> </p>   
                        <div class="allocation-chart-section" v-if='allocationLoaded && holdingsLoaded'>
                            <b>Current Holdings: </b>
                            <AllocationChart :sectorLabels="sectorLabels" :sectorValues="sectorValues" :assetLabels="assetLabels" :assetValues="assetValues" :showInfoIcon="false" 
                                :viewingOwnPortfolio="true" :holdingLabels="holdingLabels" :holdingValues="holdingValues" :allowSnapshots="false"/>
                        </div>
                </div>
            </div>
            <div class="motion-details-header">Motion Details</div>

            <div class="voting-deadline-section">
                <span class="voting-deadline-header">Voting Deadline:</span> 
                {{ activity.motion.deadline  + (activity.motion.deadline == 1 ? ' hour' : ' hours')}} ( <b>{{ deadlineDate }}</b> Approx.)
            </div>

            <div class="summary-section">
                <span class="summary-section-header">Summary:</span>
                <ul>
                    <template v-for="point of [activity.motion.summaryPoint1,activity.motion.summaryPoint2,activity.motion.summaryPoint3]">
                        <li v-if="point" :key="point">
                            {{point}}
                        </li>
                    </template>
                </ul>
            </div>


            <div class="rationale-header">Detailed Rationale:</div>
        </template>
            
       
            <div class='shared-post-body-content'>
                <!-- <div class='post-content-title' v-if="activity.title">{{activity.title}}</div> -->
                <div :id="'postActualContent-' + activity.id" class='post-actual-content' v-html="getPostContent()"></div>
                
                <div class="motion-section" v-if="activity.verb.startsWith('motion')">
                
                        
                    <div class="voting-container" >
                        <Button class="yes-vote-button"  title="Signup to have your say." @click="openAccountNeededModal">
                            <span class="vote-label">Vote YES</span>
                            <span class="material-symbols-outlined vote-icon">check_circle</span>
                        </Button>
                        <Button class="no-vote-button"  title="Signup to have your say." @click="openAccountNeededModal">
                            <span class="vote-label">Vote NO</span>
                            <span class="material-symbols-outlined vote-icon">cancel</span>
                        </Button>
                    </div>
                    
                    
                </div>

            <template v-if="activity.poll?.pollTypeId == YESNO_POLL.pollTypeId">
                    <div class="voting-container" >
                        <Button class="yes-vote-button" title="Signup to have your say." @click="openAccountNeededModal">
                            <span class="vote-label">YES</span>
                            <span class="material-symbols-outlined vote-icon">check_circle</span>
                        </Button>
                        <Button class="no-vote-button" title="Signup to have your say."  @click="openAccountNeededModal">
                            <span class="vote-label">NO</span>
                            <span class="material-symbols-outlined vote-icon">cancel</span>
                        </Button>
                    </div>
                    
                </template>
                <template v-else-if="activity.poll?.pollTypeId == MULTIPLE_CHOICE_POLL.pollTypeId">
                    <div class="polling-container" >
                        <div>
                            <div class="poll-options">
                                <div v-for="option in activity.poll.pollOptions" :key="option.pollOptionId" class="poll-option">
                                    <label :for="option.pollOptionId" class="ml-2">{{option.code}}.</label>&nbsp;
                                    <RadioButton :inputId="option.code+option.pollOptionId" name="pizza" :value="option.pollOptionId" @click="$event.stopPropagation();"/>&nbsp;
                                    <label :for="option.pollOptionId" class="ml-2">{{ option.title }}</label>
                                </div>
                            </div>
                            <Button class="poll-button"  title="Signup to have your say."  @click="openAccountNeededModal">
                                <span class="vote-label">Submit</span>
                                <span class="material-symbols-outlined vote-icon">check_circle</span>
                            </Button>
                        </div>
                    </div>
                    
                </template>
                <template v-else-if="activity.poll?.pollTypeId == MULTIPLE_SELECT_POLL.pollTypeId">
                    <div class="polling-container" >
                        <div>
                            <div class="poll-options">
                                <div v-for="option in activity.poll.pollOptions" :key="option.pollOptionId" class="poll-option">
                                    <label :for="option.pollOptionId" class="ml-2">{{option.code}}.</label>&nbsp;
                                    <Checkbox   :inputId="option.code+option.pollOptionId" name="pizza" :value="option.pollOptionId" @click="$event.stopPropagation();"/>&nbsp;
                                    <label :for="option.pollOptionId" class="ml-2">{{ option.title }}</label>
                                </div>
                            </div>
                            <Button class="poll-button" title="Signup to have your say."  @click="openAccountNeededModal">
                                <span class="vote-label">Submit</span>
                                <span class="material-symbols-outlined vote-icon">check_circle</span>
                            </Button>
                        </div>
                    </div>
                    
                </template>
            </div>
            <div class='activity-stats-bar'>
                    <span class='likes-container' @click="openAccountNeededModal">
                        <i :class="{'pi pi-thumbs-up':true, 'liked': postUtils.isAlreadyLiked(activity), 'own-post': false}"  />
                        {{ activity.extra.num_likes ? activity.extra.num_likes : '' }}
                    </span>
                    <span class='comments-container' @click="openAccountNeededModal">
                        <i class="pi pi-comment"/>
                        {{ activity.extra.num_comments > 0 ? '' + activity.extra.num_comments : '' }}
                    </span>
                </div>
        
            <div class='comments-section' data-role="comments">
                
                <div class='comment-row' v-for="comment in comments" :key="comment.id" >
                    <div class='comment-content'>
                        <div class='names-bar'>
                            <UserAvatar :imageSrc="comment.userData.data.avatar" size="sm" customStyle='flex: 0 1 auto;' @click="openAccountNeededModal"/>
                            <div class="commenter-info">
                                <span class='full-name' @click="openAccountNeededModal">{{stringUtils.fullName(comment.userData.data)}}</span>
                                <div>
                                    <span class='username' @click="openAccountNeededModal">@{{comment.userData.data.name}}</span><span class='time-posted'> | {{timeUtils.getTimeFromNow(comment.extra.created_at)}}</span>
                                </div>
                            </div>
                            
                        </div>
                        <div :id="'comment-text-' + comment.id" class='comment-text' v-html="comment.activityData.content ? comment.activityData.content : ''"></div>
                       
                        
                    </div>
                </div>

            </div>

            <button class="bottom-signup-cta" @click="goToSignUpPage">Join the Conversation &#8594;</button>
       
        <ImageDialog ref='imageDialog'/>
        <Toast position="center" group="center" style="z-index:30000;" :autoZIndex="false"/>
    </div>
    <AccountNeededModal ref='accountNeededModal' @to-login="goToLoginPage" @to-signup="goToSignUpPage" />
</template>

<script>
import {YESNO_POLL, MULTIPLE_CHOICE_POLL, MULTIPLE_SELECT_POLL, COOKIE_SHARED_FEED_POST_ID} from '@/common/constants';

import UserAvatar from '../components/profile/UserAvatar';

import ImageDialog from '../components/feed/ImageDialog';
import AccountNeededModal from '../components/modal/AccountNeededModal.vue';


import { HOME } from "@/common/config";
import FeedService from '../service/FeedService';
import PortfolioService from '../service/PortfolioService';
import PostUtils from '../utilities/PostUtils';
import StringUtils from '../utilities/StringUtils';
import TimeUtils from '../utilities/TimeUtils';
import FormattingUtils from '../utilities/FormattingUtils';
import CookieUtils from '../utilities/CookieUtils';

import Toast from 'primevue/toast';
import BrowserUtils from '../utilities/BrowserUtils';
import RadioButton from 'primevue/radiobutton';

import Checkbox from 'primevue/checkbox';

import AllocationChart from '../components/portfolio/overview/AllocationChart.vue';

export default {
    name: 'SharedPost',
   
    components: {
        Toast,
        UserAvatar,
        ImageDialog,
        AccountNeededModal,
        RadioButton,
        Checkbox,
        AllocationChart
       
    },
    data () {
        return {
            YESNO_POLL, MULTIPLE_CHOICE_POLL, MULTIPLE_SELECT_POLL,

            activity: null,
            title: null,
            noteVersion: null,
            showError: false,
            loading: false,
            noMoreResults: true,
            comments: [],
            postUtils: null,
            stringUtils: null,
            timeUtils: null,
            currencyFormatter: new FormattingUtils(),
            sectorLabels: [],
            sectorValues: [],
            assetLabels: [],
            assetValues: [],
            holdingLabels: [],
            holdingValues: [],
            allocationLoaded: false,
            holdingsLoaded: false
        }
    },
    created() {
        this.postUtils = PostUtils;
        this.stringUtils = StringUtils;
        this.timeUtils = TimeUtils;
    },
    computed: {
       
       mainTradeOrderObject() {
            return this.activity.verb.startsWith('motion') ? this.activity.motion.motionTradeOrders[0].tradeOrder : null;
        },

        mainTradeOrderText() {
            let text = '';

            if (this.activity.verb.startsWith('motion')) {

                if (this.mainTradeOrderObject.tradeOrderType === 'Market') {
                    text = 'at <b>market price</b>.';
                } else if (this.mainTradeOrderObject.tradeOrderType === 'Limit') {
                    text = `with a <b>limit price of ${this.currencyFormatter.format(this.mainTradeOrderObject.limitPrice,'$0.00')}</b>.`;
                } else if (this.mainTradeOrderObject.tradeOrderType === 'StopLimit') {
                    text = `with a <b>limit price of ${this.currencyFormatter.format(this.mainTradeOrderObject.limitPrice,'$0.00')}</b> and <b>stop price of ${this.currencyFormatter.format(this.mainTradeOrderObject.stopPrice,'$0.00')}</b>.`;
                } else if (['StopLoss', 'StopEntry'].includes(this.mainTradeOrderObject.tradeOrderType)) {
                    text = `with a <b>stop price of ${this.currencyFormatter.format(this.mainTradeOrderObject.stopPrice,'$0.00')}</b>.`;
                }
            }

            return text;
        },

        deadlineDate() {
            return TimeUtils.getFormattedTime(this.activity.motion.deadlineDate, 'YYYY-MM-DD HH:mm');
        },
         pollClosed() {
            if( this.activity.poll?.closedDate ){
                return true;
            }
            else {
                //return TimeUtils.isSameOrBeforeNow(this.activity.poll?.closedDate);
                return false;
            }
        },
    },
    mounted() {
        const feedPostId = this.$route.params.feedPostId;
        
        CookieUtils.setCookie(COOKIE_SHARED_FEED_POST_ID, feedPostId);
        
        //AppService.cleanUpApp();// in case they logged in previously
        //console.log("shared post for id "+ this.$route.params.feedPostId)
        FeedService.getSharedPost(feedPostId).then(resp=> {
            if( resp.data.status == "success"){
                console.log(JSON.stringify(resp.data))
                this.showError = false;
                this.activity = resp.data.activityResp.activity;
                this.comments = resp.data.activityResp.comments;
                this.noteVersion = resp.data.noteVersion;
                this.activity['poll'] = this.activity.extra?.poll;
                this.activity.title = this.activity.extra?.title;
                this.activity['motion'] = this.activity.extra?.motion;

                this.$nextTick(() => {
                    this.afterActivityLoaded();
                });
                try {
                    this.title = resp.data.noteVersion.title
                } catch (err) {
                    return
                }
            }
            else {
                this.showError = true;
            }
            
        });


            
    },

    methods: {   
        afterActivityLoaded() {
            let postActualContent = document.getElementById(`postActualContent-${this.activity.id}`);

            if (postActualContent) {
                Array.from(postActualContent.getElementsByTagName('IMG')).forEach((img) => {
                    img.addEventListener('click', () => {
                        this.$refs.imageDialog.open(img.src, img.height > img.width);
                    })
                })

                Array.from(postActualContent.getElementsByTagName('A')).forEach((link) => {
                    link.addEventListener('click', (e) => {
                        e.preventDefault();
                        this.openAccountNeededModal();
                    })
                })
            }

            if (this.activity.verb.startsWith('motion')) {
                this.allocationLoaded = false;
                this.holdingsLoaded = false;

                this.getGroupPortfolioMemberBreakdown();
                this.getGroupPortfolioMemberHoldings();
            }
            this.loading = false;
           
            
        },

        getPostContent() {
          
            let content = (this.noteVersion ? this.noteVersion.content : this.activity.extra.content);

            if( this.noteVersion  && this.noteVersion.noteVersionTags.length ) {
                
                let tagSection = "<div><strong>Tags </strong>";
                this.noteVersion.noteVersionTags.forEach( tag => {
                    tagSection = tagSection + this.buildTag(tag) +"&nbsp;";
                });
                tagSection = tagSection+ "</div>"
                content = content + tagSection;
            }
            else if (this.activity.verb.startsWith('motion')) {
                    content = this.activity.motion.details;
            } 

          return content;
        },

        buildTag( tag ) {
            return '<a class="mention" href="/tag/profile/'+tag.tagId+'">#'+tag.name+'</a>';
        },

        isMobile() {
            return BrowserUtils.isMobile();
        },

        goHome() {
            window.location.href= HOME;
        },
        goToSignUpPage() {
           
            this.$router.push('/signup?ref='+this.activity.actor.id);
        },

        goToLoginPage() {
            this.$router.push('/login')
        },

        openAccountNeededModal() {
            console.log('WWWWEWEEEE')
            this.$refs.accountNeededModal.open();
        },

        getGroupPortfolioMemberBreakdown() {
            PortfolioService.getPublicGroupPortfolioMemberBreakdown(this.activity.motion.groupPortfolioId, this.activity.motion.investmentAccountId).then(allocationData=> {
                if (allocationData.status === 200) {
                    this.getDataFromPortfolioAllocation(allocationData.data);
                    // console.log("getGroupPortfolioMemberBreakdown", this.selectedAccountData, allocationData.data);
                }
                this.allocationLoaded = true;
            });
        },

        getDataFromPortfolioAllocation(allocationData) {
            this.sectorLabels = [];
            this.sectorValues = [];
            this.assetLabels = [];
            this.assetValues = [];

            if (allocationData.status === 'error') {
                return;
            }

            const sectorData = allocationData.sectorMapping.dataPoints.filter((data) => {
                return data.value > 0;
            });
            const assetData = allocationData.classMapping.dataPoints.filter((data) => {
                return data.value > 0;
            });

            const labelValueGenerator = (data, sectorOrAsset) => {
                let formattedCategory = '';
                const splitCategory = data.category.split(' ');
                const formattedWords = splitCategory.map((word) => {
                    return word.replace(word[0], word[0].toUpperCase());
                });

                if (formattedWords.length > 1) {
                    formattedCategory = formattedWords.join(' ');
                } else {
                    formattedCategory = formattedWords[0];
                }

                if (sectorOrAsset === 'sector') {
                    this.sectorLabels.push(formattedCategory);
                    this.sectorValues.push((data.value * 100).toFixed(2));
                } else if (sectorOrAsset === 'asset') {
                    this.assetLabels.push(formattedCategory);
                    this.assetValues.push((data.value * 100).toFixed(2));
                }
            };

            sectorData.forEach((data) => {
                labelValueGenerator(data, 'sector');
            });

            assetData.forEach((data) => {
                labelValueGenerator(data, 'asset');
            });
        },

        getGroupPortfolioMemberHoldings() {
            PortfolioService.getPublicGroupPortfolioMemberHoldings(this.activity.motion.groupPortfolioId, this.activity.motion.investmentAccountId).then(assetPositionData=>{
                if (assetPositionData.status === 200) {
                    this.getDataFromPortfolioHoldings(assetPositionData.data);
                }
                this.holdingsLoaded = true;
            });
        },

        getDataFromPortfolioHoldings(assetPositionData) {
            if (assetPositionData.status === 'error') {
                return;
            }

            const equityPositions = assetPositionData.holdings.equityPositions;
            const fundPositions = assetPositionData.holdings.fundPositions;
            const derivativePositions = assetPositionData.holdings.derivativePositions;
            const fixedIncomePositions = assetPositionData.holdings.fixedIncomePositions;
            const otherPositions = assetPositionData.holdings.otherAssetPositions;

            const publicHoldingsArray = [];

            if (equityPositions.length > 0) {
                equityPositions.forEach((holding) => {
                    publicHoldingsArray.push(holding);
                });
            }

            if (fundPositions.length > 0) {
                fundPositions.forEach((holding) => {
                    publicHoldingsArray.push(holding);
                });
            }

            if (derivativePositions.length > 0) {
                derivativePositions.forEach((holding) => {
                    publicHoldingsArray.push(holding);
                });
            }

            if (fixedIncomePositions.length > 0) {
                fixedIncomePositions.forEach((holding) => {
                    publicHoldingsArray.push(holding);
                });
            }

            if (otherPositions.length > 0) {
                otherPositions.forEach((holding) => {
                    publicHoldingsArray.push(holding);
                });
            }

            const orderedHoldingsArray = publicHoldingsArray.sort((a, b) => {
                return b.weight - a.weight;
            });

            this.holdings = orderedHoldingsArray;

            this.holdingLabels = [];
            this.holdingValues = [];

            let cumulativeWeight = 0;

            this.holdings.forEach(holding => {
                const weight = holding.weight * 100;
                this.holdingValues.push(weight);
                cumulativeWeight += weight;
                
                const ticker = holding.otherAsset?.proxySymbol || holding.otherAsset?.symbol || holding.derivative?.symbol || holding.securityListing?.symbol || holding.fund?.symbol;
                this.holdingLabels.push(ticker);
            });

            this.holdingValues.push(100 - cumulativeWeight);
            this.holdingLabels.push('Other');
        },
    },
        
    
}
</script>
<style>
 .post-actual-content ol li:before {
        content: counter(list-0,decimal) ". ";
    }
    .post-actual-content ol li.ql-indent-1:before {
        content: counter(list-1,lower-alpha) ". ";
    }

    .post-actual-content ol li.ql-indent-2:before {
        content: counter(list-2,lower-roman) ". ";
    }
    .post-actual-content ol li.ql-indent-3:before {
        content: counter(list-3,decimal) ". ";
    }
    .post-actual-content ol li.ql-indent-4:before {
        content: counter(list-4,lower-alpha) ". ";
    }
    .post-actual-content ol li.ql-indent-5:before {
        content: counter(list-5,lower-roman) ". ";
    }
    .post-actual-content ol li.ql-indent-6:before {
        content: counter(list-6,decimal) ". ";
    }
    .post-actual-content ol li.ql-indent-7:before {
        content: counter(list-7,lower-alpha) ". ";
    }
    .post-actual-content ol li.ql-indent-8:before {
        content: counter(list-8,lower-roman) ". ";
    }

 .post-actual-content li:before {
    display: inline-block;
    white-space: nowrap;
    width: 1.2em;
}
.post-actual-content li:not(.ql-direction-rtl):before {
    margin-left: -1.5em;
    margin-right: .3em;
    text-align: right;
}

.post-actual-content ul>li:before {
    content: "\2022";
}

.post-actual-content li:before {
    display: inline-block;
    white-space: nowrap;
    width: 1.2em;
}

  .post-actual-content ol>li, .post-actual-content ul>li {
    list-style-type: none;
} 

.post-actual-content ol, .post-actual-content ul{
    
    counter-reset: list-1 list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;
    list-style-position: inside;
}

 .post-actual-content ol li {
    counter-reset: list-1 list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;
    counter-increment: list-0
}

.post-actual-content ol li.ql-indent-1 {
    counter-reset: list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;
    counter-increment: list-1;
} 

/* .post-actual-content ol li.ql-indent-1 {
    counter-increment: list-1;
} */

.post-actual-content ol li.ql-indent-2 {
    counter-reset: list-3 list-4 list-5 list-6 list-7 list-8 list-9;
}

.post-actual-content ol li.ql-indent-2 {
    counter-increment: list-2;
}

.post-actual-content ol li.ql-indent-3 {
    counter-reset: list-4 list-5 list-6 list-7 list-8 list-9;
}

.post-actual-content ol li.ql-indent-3 {
    counter-increment: list-3;
}

.post-actual-content ol li.ql-indent-4 {
    counter-reset: list-5 list-6 list-7 list-8 list-9;
}

.post-actual-content ol li.ql-indent-4 {
    counter-increment: list-4;
}

.post-actual-content ol li.ql-indent-5 {
    counter-reset: list-6 list-7 list-8 list-9;
}

.post-actual-content ol li.ql-indent-5 {
    counter-increment: list-5;
}

.post-actual-content ol li.ql-indent-6 {
    counter-reset: list-7 list-8 list-9;
}

.post-actual-content ol li.ql-indent-6 {
    counter-increment: list-6;
}

.post-actual-content ol li.ql-indent-7 {
    counter-reset: list-8 list-9;
}

.post-actual-content ol li.ql-indent-7 {
    counter-increment: list-7;
}

.post-actual-content ol li.ql-indent-8 {
    counter-reset: list-9;
}

.post-actual-content ol li.ql-indent-8 {
    counter-increment: list-8;
}
</style>

<style scoped>
*:not(.pi, .material-symbols-outlined), .p-component {
    font-family: "Trebuchet MS", "Verdana";
}

::v-deep(.post-actual-content ol li:not(.ql-direction-rtl), .post-actual-content ul li:not(.ql-direction-rtl) ){
    padding-left: 1.5em;
}

::v-deep(.post-actual-content li.ql-indent-1:not(.ql-direction-rtl) ){
    padding-left: 4.5em;
}
::v-deep(.post-actual-content li.ql-indent-2:not(.ql-direction-rtl) ){
    padding-left: 7.5em;
}
::v-deep(.post-actual-content li.ql-indent-3:not(.ql-direction-rtl) ){
    padding-left: 10.5em;
}
::v-deep(.post-actual-content li.ql-indent-4:not(.ql-direction-rtl) ){
    padding-left: 13.5em;
}
::v-deep(.post-actual-content li.ql-indent-5:not(.ql-direction-rtl) ){
    padding-left: 16.5em;
}
::v-deep(.post-actual-content li.ql-indent-6:not(.ql-direction-rtl) ){
    padding-left: 19.5em;
}
::v-deep(.post-actual-content li.ql-indent-7:not(.ql-direction-rtl) ){
    padding-left: 22.5em;
}
::v-deep(.post-actual-content li.ql-indent-8:not(.ql-direction-rtl) ){
    padding-left: 25.5em;
}

::v-deep(.post-actual-content .ql-indent-1:not(.ql-direction-rtl)){
    padding-left: 3em;
}
::v-deep(.post-actual-content .ql-indent-2:not(.ql-direction-rtl)){
    padding-left: 6em;
}
::v-deep(.post-actual-content .ql-indent-3:not(.ql-direction-rtl)){
    padding-left: 9em;
}
::v-deep(.post-actual-content .ql-indent-4:not(.ql-direction-rtl)){
    padding-left: 12em;
}
::v-deep(.post-actual-content .ql-indent-5:not(.ql-direction-rtl)){
    padding-left: 15em;
}
::v-deep(.post-actual-content .ql-indent-6:not(.ql-direction-rtl)){
    padding-left: 18em;
}
::v-deep(.post-actual-content .ql-indent-7:not(.ql-direction-rtl)){
    padding-left: 21em;
}
::v-deep(.post-actual-content .ql-indent-8:not(.ql-direction-rtl)){
    padding-left: 24em;
}



.trade-details-section .allocation-chart-section {
    display: flex;
}
.trade-details-section .allocation-chart-section ::v-deep(.allocation-card) {
    width: 50%;
    margin-left: 24px;
}

.trade-details-section {
    padding: 15px 0;
    margin-bottom: 24px;
}
.trade-details-section .trade-details-header {
    font-weight:bold;
    color: #32364e;
    font-size: 20px;
    margin-bottom: 16px;
}
.trade-details-section .trade-details-content ::v-deep(b),
.trade-details-section .trade-details-content b {
    color: black;
}
.trade-details-section .allocation-chart-section {
    display: flex;
}
.trade-details-section .allocation-chart-section ::v-deep(.allocation-card) {
    width: 50%;
    margin-left: 24px;
}

.motion-details-header {
    font-weight:bold;
    color: #32364e;
    font-size: 20px;
    margin-bottom: 16px;
}

.voting-deadline-section {
    margin-bottom: 16px;
}
.voting-deadline-section .voting-deadline-header {
    font-weight:bold;
    color: #32364e;
}
.voting-deadline-section ::v-deep(b) {
    color: black;
}

.summary-section {
    margin-bottom: 20px;
    font-size: 14px;
}

.summary-section-header {
    font-weight: bold;
    color: #32364E;
    font-size: 14px;
    margin-bottom: 10px;
}
.summary-section ul {
    word-wrap: break-word;
    padding-inline-start: 40px;
}

.rationale-header {
    font-weight: bold;
    color: #32364E;
    margin-bottom: 10px;
}

.motion-section {
    border-top: 1px solid #BFBFBF;
    margin: 24px 0;
    padding-top: 12px;
}

.motion-status-text {
    text-align: center;
    font-size: 24px;
    font-weight: bold;
    color: black;
}
.voted-text {
    color: black;
    font-size: 24px;
    font-weight: bold;
}
.voted-text ::v-deep(.yes) {
    color: #33CC99;
}
.voted-text ::v-deep(.no) {
    color: #E63E3E;
}

.motion-security-section {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 24px;
}
.motion-security-section .security-exchange-text {
    font-weight: bold;
    margin-bottom: 12px;
}
.motion-security-section ::v-deep(.p-grid) {
    width: 100%;
    margin-top: 0;
    margin-right: 0;
}
.motion-security-section ::v-deep(.p-grid > div:first-child) {
    padding-left: 8px;
}
.motion-security-section .security-table .label-container {
    text-align: left;
}
.motion-security-section .security-table .value-container {
    font-weight: bold;
}

.question-post {
    color: #32364E; /*#33CC99;*/
    position: relative;
    top: 4px;
    margin-right: 5px;
    font-size: 36px;
}

.poll {
    font-size:22px;
    left: 14px;
    top: -10px;
}

.motion-post {
    color: #32364E; /*#33CC99;*/
    position: relative;
    top: 4px;
    margin-right: 5px;
    font-size: 26px;
}

.poll-option{
    margin-bottom: 5px;
    font-size: 1rem;
}

.poll-options {
    padding-bottom: 15px;
}

.polling-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 45px;
}
.voting-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 45px;
}
.voting-container button, .polling-container button {
    display: flex;
    align-items: center;
    border: 2px solid #32364E;
    border-radius: 50px;
    padding: 0px 8px;
    background: none;
}
.voting-container button:hover, .polling-container button:hover{
    transform:rotate(0.2deg);
    transition:transform 0.25s cubic-bezier(0.5,400,0.5,-400);
    animation-iteration-count: infinite;
    background: none;
    border-color: none;
    box-shadow: none;
}
.voting-container .yes-vote-button, .polling-container .yes-vote-button {
    margin-right: 12px;
}
.voting-container .yes-vote-button:hover, .polling-container .poll-button:hover {
    border-color: #33CC99;
}
.voting-container .no-vote-button:hover {
    border-color: #E63E3E;
}
.voting-container .vote-label, .polling-container .vote-label{
    font-weight: bold;
    color: #32364e;
    font-size: 14px;
}
.voting-container .yes-vote-button .vote-icon, .polling-container .poll-button .vote-icon{
    color: #33CC99;
}
.voting-container .no-vote-button .vote-icon {
    color: #E63E3E;
}
.voting-container .vote-icon , .polling-container .vote-icon{
    font-size: 24px;
    
}

::v-deep(figure.table > table) {
    width: 100%;
}

.sign-up-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 80px;
    position: fixed;
    background-color: #fff;
    padding: 0 30px;
    z-index: 1;
}

.nav-image {
    height: 50px;
}

.nav-items {
    display: flex;
    align-items: center;
}

.login-link {
    text-decoration: none;
    margin-right: 20px;
    font-size: 16px;
    color: #333;
    font-weight: 600;
}

.login-link:hover {
    cursor: pointer;
}

.signup-cta, .bottom-signup-cta {
  padding: 10px 20px;
  border-radius: 8px;
  border: none;
  font-weight: 500;
  font-size: 20px;
  color: #fff;
  background-image: linear-gradient(
    to right,
    #693bf5,
    rgb(105, 59, 245, 0.85),
    rgb(105, 59, 245, 0.7)
  );
  transition: all 0.1s ease-in;
}

.signup-cta:hover, .bottom-signup-cta:hover {
    box-shadow: 0 0.4rem 3.2rem rgb(105, 59, 245, 0.6);
    transform: scale(1.05);
    cursor: pointer;
}

.signup-cta:active, .bottom-signup-cta:active {
    transform: scale(0.95);
}

.bottom-signup-cta {
    display: flex;
    justify-content: center;
    margin: 0 auto;
    margin-bottom: 80px;
}

.shared-post-div {
    margin: 0 auto;
    margin-top: 80px;
    padding: 10px;
    max-width:700px;
    z-index: 1;
}

.shared-page-title {
    color: #32364e;
    font-weight: 700;
    margin-bottom: 20px;
    font-size: 36px;
    font-family: sans-serif;
    display: inline;

   
}

.activity-title {
    margin-bottom: 20px;
}

.shared-post-body-content {
    padding: 15px 0;
}

.comments-section {
    margin: 15px 0;
    padding: 15px 0;
    border-top: 1px solid #a6a6a6;
}

.comment-row {
    padding: 8px 0;
}

.names-bar {
    display: flex;
    align-items: top;
    margin-bottom: 5px;
}

.commenter-info {
    display: flex;
    flex-direction: column;
}

.full-name {
    font-size: 14px;
    color: #32364e;
    font-weight: 600;
}

.username {
    color: #a6a6a6;
    margin-right: 4px;
    font-size: 12px;
}

.full-name:hover, .username:hover {
    cursor: pointer;
}

.time-posted {
    color: #a6a6a6;
    font-size: 12px;
}

.comment-text {
    padding-left: 34px;
    color: #000;
}

.p-float-label {
    width: 50%;
}
.input-label {
    font-size: 20px;
    padding-left: 20px;
    line-height: 0.8;
}

.button-container {
    display: flex;
}

.status-post-owner-info {
    display: flex;
    align-items: center;
    width: 100%;
}
.names-container {
    padding-left: 5px;
}
.status-post-owner-info .names-container {
    display: block;
}
.status-post-owner-info .names-container .full-name {
    display: block;
    color: #32364e;
    font-size: 18px;
    font-weight: bold;
}
.status-post-owner-info .names-container .full-name:hover {
    cursor: pointer;
}
.status-post-owner-info .names-container .username {
    display: block;
    color: #a6a6a6;
    font-size: 12px;
}
.status-post-owner-info .names-container .username span:hover {
    cursor: pointer;
}
.status-post-owner-info .more-options {
    margin-left: auto;
}

.post-body-content {
    padding: 16px;
}
.post-body-content .post-content-title {
    font-weight: bold;
    font-size: 24px;
    color: #32364e;
    padding-bottom: 16px;
}
 .activity-stats-bar {
    display: flex;
    width: 100%;
    align-items: center;
    margin-top: 16px;
}
 .activity-stats-bar  [class*='container'] {
    display: flex;
    align-items: center;
    color: #32364e;
}
 .activity-stats-bar  [class*='container'] i {
    font-size: 24px;
    margin-right: 8px;
}
 .activity-stats-bar .likes-container {
    margin-right: 16px;
}
.activity-stats-bar .likes-container i.liked,
.activity-stats-bar .likes-container i:not(.own-post):hover {
    cursor: pointer;
    color: #33CC99;
}

.post-actual-content {
    word-wrap: break-word;
    font-size: 16px;
}

::v-deep(.post-actual-content p) {
    color: black;
}
::v-deep(.post-actual-content .image.image_resized) {
    width: auto !important;
}
::v-deep(.post-actual-content .image img) {
    max-width: 100%;
    max-height: 100%;
    width: unset;
    height: unset;
}
::v-deep(.post-actual-content img) {         
    max-width: 100%;
    padding: 8px 0px;
}
::v-deep(.post-actual-content img:hover) {
    cursor: pointer;
}

a {
    font-weight: bold;
}

:deep(.analyst-mention)  {
    color: #693BF5; 
    font-weight: bold;
}
:deep(.security-mention), ::v-deep(.tag-mention) {
    color: #33CC99;
    font-weight: bold;
}

.more-options {
    float: right;
}

.p-avatar:hover {
    cursor: pointer;
}

@media (max-width: 760px) {
    .input-container { flex-direction: column;}

    :deep(.comment-text p img) {
        max-width: 300px;
    }
        
    ::v-deep(.p-scrollpanel-content) {
            padding: 0 !important;
        }


    .sign-up-header {
        padding: 0 10px;
        height: 60px;
    }

    .nav-image {
        height: 30px;
    }

    .login-link {
        margin-right: 12px;
        font-size: 14px;
        font-weight: 600;
    }

    .shared-post-div {
        margin-top: 60px;
    }

    .shared-page-title {
        margin-bottom: 15px;
        font-size: 28px;
    }

    .signup-cta, .bottom-signup-cta {
    padding: 8px 16px;
    border-radius: 8px;
    border: none;
    font-weight: 500;
    font-size: 16px;
    color: #fff;
    background-image: linear-gradient(
        to right,
        #693bf5,
        rgb(105, 59, 245, 0.85),
        rgb(105, 59, 245, 0.7)
    );
    transition: all 0.1s ease-in;
    }

    .signup-cta:hover, .bottom-signup-cta:hover {
        box-shadow: 0 0.4rem 3.2rem rgb(105, 59, 245, 0.6);
        transform: scale(1.05);
        cursor: pointer;
    }

    .signup-cta:active, .bottom-signup-cta:active {
        transform: scale(0.95);
    }

    .bottom-signup-cta {
        display: flex;
        justify-content: center;
        margin: 0 auto;
        margin-bottom: 100px;
    }

    .trade-details-section .allocation-chart-section {
        flex-direction: column;
    }
    .trade-details-section .allocation-chart-section ::v-deep(.allocation-card) {
        width: 100%;
        margin-left: 0;
    }
}

::v-deep(.embedly-card) {
    max-height: 680px;
   
    }
::v-deep(.embedly-card-hug) {
     transform-origin: top center;
    -webkit-transform:scale(0.9);
    -moz-transform-scale:scale(0.9);
    
}

 ::v-deep(.insta-iframe) {
     height:760px;
    
 }


 ::v-deep(.insta-blockquote){
     max-height: 500px;
     
     transform-origin: top center;
     -webkit-transform:scale(0.7);
    -moz-transform-scale:scale(0.7);
 }
</style>
